<template>
  <div class="page-wrapper">

    <div class="calc-wrapper">

      <!-- Calc Form -->
      <h1 class="calc__form-header">Bereken je salaris.</h1>
      <p class="calc__form-text">Je salaris hangt af van je leeftijd en het aantal uur dat je per week gaat werken.</p>
      <SalaryForm :formData="formData" @submitted="submitted"></SalaryForm>

      <!-- Calc Output -->
      <template v-if="ageData.salary">
        <h1 class="calc__output-header">Jouw salaris</h1>
        <SalaryOutput :data="ageData" @submitForm="(data) => formData = data"></SalaryOutput>
      </template>

      <!-- Info and disclaimers -->
      <div class="calc__info">
        <img src="../assets/img/Info.svg" alt="Info icoon">

        <p>Vakanties of andere perioden waarin je even meer tijd hebt om te werken? Je kunt dan tot 40 uur per week aan de slag. Bereken <a @click="formData = {age: null, hours: 40}">hier</a> wat je maximaal in een vakantie week kunt verdienen.</p>
<!--        <p v-else>Vakanties of andere perioden waarin je even meer tijd hebt om te werken? Dat kan! Je kunt dan meer uur bij ons komen werken.</p>-->
      </div>

      <p class="calc__disclaimer">* Aan deze berekening kunnen geen rechten worden ontleend. Jouw exacte loon staat straks op jouw contract.</p>
      <p class="calc__disclaimer">* Dit is jouw all-in bruto loon. Met een bijbaan bij Lidl krijg je maandelijks alles in een keer uitbetaald. Inclusief verlof uren, ADV uren en een vakantietoeslag.</p>

    </div>

    <div class="img-wrapper">
      <img class="desktop-img" src="../assets/img/Cover.webp" alt="Werknemers Lidl">
    </div>
    
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SalaryForm from '../components/SalaryForm.vue'
import SalaryOutput from '../components/SalaryOutput.vue';

import { ageData, formData }  from '../types';

export default defineComponent({
  name: 'SalaryCalView',
  components: {
    SalaryForm,
    SalaryOutput
  },
  data(){
    return{
      ageData: {} as ageData,
      formData: {} as formData,

      // For now passing age from form after feedback from Lidl
      // Consider using store if app expands, cause they want the form to be filled in from anywhere on the page
      age: 0 as number,
      hours: 0 as number,
    }
  },
  methods:{
    submitted(params: {data: ageData, age: number, hours: number}) {
      this.ageData = params.data;
      this.age = params.age;
      this.hours = params.hours;
    }
  },
});
</script>

<style lang="less" scoped>

.calc-wrapper{
  padding: 32px 24px;

  >p:nth-last-of-type(1){
    margin-top: 16px;
  }
}

.calc__form-header{
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
}

.calc__form-text{
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 32px;
}

.calc__output-header{
  margin-bottom: 8px;
  font-size: 22px;
}

.calc__info{
  padding: 8px 8px 8px 16px;
  background: #A0D2FF;
  border-radius: 2px;
  color: #0050AA;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 24px;

  >p{
    font-weight: 400;
    font-size: 12px;
  }

  a{
    text-decoration: none;
    font-weight: 600;
    color: #0050AA;
    border-bottom: 1px solid #0050AA;
  }

  a:hover{
    cursor: pointer;
  }
}

.calc__disclaimer:nth-of-type(1){
  margin-bottom: 16px;
}
  
.img-wrapper{
  display: none;
  position: relative;

  .desktop-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 60%;
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .page-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 960px;
  }

  .img-wrapper{
    display: block;
  }
}

@media screen and (min-width: 980px){
  .page-wrapper{
    min-height: 900px;
  }

  .calc__form-header{
    text-align: unset;
  }
}
</style>