<template>
    <form class="calc__form" @submit.prevent="$emit('submitted', {data: getAgeData(age, job, hours), age: age, hours: hours})">

        <!-- Age selection, currently 15-21+ -->
        <div>
            <label class="form__label" for="age">Hoe oud ben je?</label>
            <select class="form__select" name="age" id="age" v-model="age">
                <option v-for="a in 7" :value="a + 14" :key="a">{{ a + 14 }}{{ a == 7 ? '+' : '' }} jaar</option>
                <option v-if="formData?.age" :value="formData.age" hidden>{{ formData.age }}</option>
            </select>
        </div>

        <!-- Hours selection -->
        <div>
            <label class="form__label" for="hours">Hoeveel uur per week wil je werken?</label>
            <select class="form__select" name="hours" id="hours" v-model="hours">
                <option v-for="h in 39" :value="h + 1" :key="h">{{ h + 1 }} uur</option>
                <option v-if="formData?.hours" :value="formData.hours" hidden>{{ formData.hours }} uur</option>
            </select>
        </div>

        <input class="form__button" type="submit" value="Bereken salaris" ref="submit_btn">
    </form>   
</template>
  
<script lang="ts">
  import { defineComponent } from 'vue';
  import salaryMixin from '../mixins/salaryMixin';
  import { formData }  from '../types';
  
  export default defineComponent({
    name: 'SalaryForm',
    mixins: [salaryMixin],
    props: {
        formData:{
            required: false,
            type: Object as () => formData
        }
    },
    data(){
      return{
        job: "Hulpkracht",
        age: 0 as number,
        hours: 0 as number,
      }
    },
    watch: {
        // Watches event comming from SalaryOutput.vue.
        formData(){
            if(this.$props.formData?.hours){
                this.hours = this.$props.formData?.hours;
            }
            
            if(this.$props.formData?.age){
                this.age = this.$props.formData?.age;
            }

            // Currently disabled, because you need aditional library for TS and refs
            /* eslint-disable */
            (this.$refs.submit_btn as any).click();
        }
    }
  });
</script>
  
<style lang="less" scoped>

.calc__form{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    >div{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.form__label {
  font-weight: bold;
}

.form__select{
    border: none;
    border-radius: 2px;
    padding: 10px 15px;
    border-right: 15px solid transparent;
    font-weight: 800;
    color: #000000;
}

.form__button{
    padding: 10px 15px;
    background: #FFF000;
    border: none;
    border-radius: 2px;
    max-width: fit-content;
    align-self: flex-end;
    font-size: 12px;
    color: black;
}

.form__button:hover, .form__select:hover{
    cursor: pointer;
}
    
</style>